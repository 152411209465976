<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("欢迎回来") }}</div>
        <div class="account">
          {{ handleAccount(this.$route.query.account) }}
        </div>
      </div>
      <div class="form">
        <el-form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          @submit.native.prevent
        >
          <el-form-item prop="password" :label="translateTitle('密码')">
            <el-input
              v-model="formData.password"
              :placeholder="translateTitle('请输入密码')"
              :type="showPassword ? 'text' : 'password'"
              :disabled="$route.query.tokenVerify"
            >
              <template #suffix>
                <div class="suffix-btn">
                  <ClearIcon
                    v-if="!!formData.password"
                    style="margin-right: 10px"
                    @click="formData.password = ''"
                  />
                  <EyeIcon
                    v-if="showPassword"
                    @click="showPassword = !showPassword"
                  />
                  <EyeHideIcon v-else @click="showPassword = !showPassword" />
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>

        <el-button class="next-btn" type="primary" @click="onSubmitPassword">
          <span v-if="!loading">{{ translateTitle("下一步") }}</span>
          <Loading v-else />
        </el-button>
        <div class="other-btn" @click="onForget">
          {{ translateTitle("忘记登录密码 ?") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import ClearIcon from "@/components/icons/ClearIcon.vue";
import { UCenterRequest } from "@/model/UCenterService";
import md5 from "md5";
import Cookies from "js-cookie";
import * as Account from "@/model/user/account";
import Loading from "@/components/common/Loading.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeHideIcon from "@/components/icons/EyeHideIcon.vue";
export default {
  components: {
    ClearIcon,
    Loading,
    EyeIcon,
    EyeHideIcon,
  },
  data() {
    return {
      logoIcon,
      formRules: {
        password: [
          {
            required: true,
            message: this.translateTitle("请输入密码"),
            trigger: "blur",
          },
        ],
      },
      formData: {
        password: "",
      },
      loading: false,
      showPassword: false,
    };
  },
  created() {
    if (this.$route.query.tokenVerify) {
      this.onVerifySuccess();
    }
  },
  methods: {
    handleAccount(account) {
      if (!account) return "";
      if (!account.includes("@")) {
        return account.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
      }
      return account.replace(/(\w{1}).*(\w{1})@(.*)/, "$1***$2@$3");
    },
    async onSubmitPassword() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        this.loading = true;

        const res = await UCenterRequest.checkPassword({
          login_name: this.$route.query.account,
          pwd: md5(this.formData.password),
          token_login: this.$route.query.tokenLogin,
        });

        if (res.status === 200) {
          this.userData = res.data;
          const status = res.data.status;
          if (status === 3) {
            window.localStorage.setItem("exchange_h5_token", res.data["token"]);

            this.$router.push({
              name: "SafetyVerify",
              query: {
                ...this.$route.query,
                source: "login",
              },
              params: {
                ...this.$route.params,
              },
            });
          } else if (status === 4) {
            const loginRes = await UCenterRequest.login({
              login_name: this.$route.query.account,
              token_login: this.$route.query.tokenLogin,
            });

            if (loginRes.status === 200) {
              const userInfo = loginRes.data;
              const { tokenInfo } = userInfo;
              this.$sensors.login(userInfo.user_id);
              Cookies.set("topcredit_exchange_t", tokenInfo.token || null, {
                expires: 7,
              });
              sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);

              await Account.writeUserInfo(userInfo, true);
              this.$store.dispatch("user/getUserInfo").then((res) => {
                let jump = this.$route.params.jump;
                if (jump) {
                  //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
                  location.href = jump;
                  return;
                } else {
                  window.location.href = "/";
                }
              });
            }
          }
        } else {
          this.formData.password = "";
        }
        this.loading = false;
      });
    },

    onForget() {
      this.$router.push({
        name: "ForgetPwd",
        query: {
          ...this.$route.query,
        },
      });
    },

    async onVerifySuccess() {
      const tokenVerifyStr = this.$route.query.tokenVerify;
      let tokenVerify = "";
      if (tokenVerifyStr) {
        const data = JSON.parse(window.decodeURIComponent(tokenVerifyStr));
        tokenVerify = Object.values(data).filter(Boolean)[0];
      }
      this.loading = true;
      const area = this.$route.query.area;
      const res = await UCenterRequest.checkSafetyVerify({
        login_name: this.$route.query.account,
        token_login: this.$route.query.tokenLogin,
        token_verify: tokenVerify,
        area,
      });
      if (res.status === 200) {
        const loginRes = await UCenterRequest.login({
          login_name: this.$route.query.account,
          token_login: this.$route.query.tokenLogin,
          area,
        });

        if (loginRes.status === 200) {
          const userInfo = loginRes.data;
          const { tokenInfo } = userInfo;
          this.$sensors.login(userInfo.user_id);
          Cookies.set("topcredit_exchange_t", tokenInfo.token || null, {
            expires: 7,
          });
          sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);

          await Account.writeUserInfo(userInfo, true);
          this.$store.dispatch("user/getUserInfo").then((res) => {
            let jump = this.$route.params.jump;
            if (jump) {
              //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
              location.href = jump;
              return;
            } else {
              window.location.href = "/";
            }
          });
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";
.account {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 12px;
}

.next-btn {
  margin-top: 118px !important;
}
.other-btn {
  text-align: left !important;
}
</style>
